<template>
  <div>

    <!-- 3D   greenball  blueball-->
    <div class="res-open-content type-ssg lgklsf">
      <div class="r-o-ssg">
        <table class="com-table">
          <tr>
            <th rowspan="2" width="80">期数</th>
            <th rowspan="2" width="140">时间</th>
            <th colspan="6">平码一</th>
            <th colspan="6">平码二</th>
            <th colspan="6">平码三</th>
            <th colspan="6">平码四</th>
            <th colspan="6">特码</th>

            <th colspan="4">总和</th>
            <th rowspan="2">龙虎</th>
         
          </tr>
          <tr>
          
           <th>号码</th>
           <th>单双</th>
           <th>大小</th>
           <th>尾</th>
           <th>合</th>
           <th>福禄</th>
           <th>号码</th>
           <th>单双</th>
           <th>大小</th>
           <th>尾</th>
           <th>合</th>
           <th>福禄</th>
           <th>号码</th>
           <th>单双</th>
           <th>大小</th>
           <th>尾</th>
           <th>合</th>
           <th>福禄</th>
           <th>号码</th>
           <th>单双</th>
           <th>大小</th>
           <th>尾</th>
           <th>合</th>
           <th>福禄</th>
           <th>号码</th>
           <th>单双</th>
           <th>大小</th>
           <th>尾</th>
           <th>合</th>
           <th>福禄</th>
           <th>总</th>
           <th>单双</th>
           <th>大小</th>
           <th>尾</th>
           
          </tr>
          <tr v-for="item in list" :key="item.Expect">
            <td>{{item.Expect}}</td>
            <td>{{item.Opentime|substrSome}}</td>
            <!-- --------------------------------- -->
             <td class="td-ball"> <p :class="Number(item.codeArr[0])>40?'senseball':'greyball'">{{item.codeArr[0]}}</p></td>
             <td :class="fontColor[panduanhaoma(item.codeArr[0]).ds]">{{ panduanhaoma(item.codeArr[0]).ds }} </td>
             <td :class="fontColor[panduanhaoma(item.codeArr[0]).dx]">{{ panduanhaoma(item.codeArr[0]).dx }} </td>
             <td :class="fontColor[panduanhaoma(item.codeArr[0]).wdx]">{{ panduanhaoma(item.codeArr[0]).wdx }} </td>
             <td :class="fontColor[panduanhaoma(item.codeArr[0]).hds]">{{ panduanhaoma(item.codeArr[0]).hds }} </td>
             <td class="ltgreen">{{ panduanhaoma(item.codeArr[0]).fulu }} </td>
             <!--        ------------ -->
             <td class="td-ball"> <p :class="Number(item.codeArr[1])>40?'senseball':'greyball'">{{item.codeArr[1]}}</p></td>
             <td :class="fontColor[panduanhaoma(item.codeArr[1]).ds]">{{ panduanhaoma(item.codeArr[1]).ds }} </td>
             <td :class="fontColor[panduanhaoma(item.codeArr[1]).dx]">{{ panduanhaoma(item.codeArr[1]).dx }} </td>
             <td :class="fontColor[panduanhaoma(item.codeArr[1]).wdx]">{{ panduanhaoma(item.codeArr[1]).wdx }} </td>
             <td :class="fontColor[panduanhaoma(item.codeArr[1]).hds]">{{ panduanhaoma(item.codeArr[1]).hds }} </td>
             <td class="ltgreen">{{ panduanhaoma(item.codeArr[1]).fulu }} </td>
             <!-- -------- -->
             <td class="td-ball"> <p :class="Number(item.codeArr[2])>40?'senseball':'greyball'">{{item.codeArr[2]}}</p></td>
             <td :class="fontColor[panduanhaoma(item.codeArr[2]).ds]">{{ panduanhaoma(item.codeArr[2]).ds }} </td>
             <td :class="fontColor[panduanhaoma(item.codeArr[2]).dx]">{{ panduanhaoma(item.codeArr[2]).dx }} </td>
             <td :class="fontColor[panduanhaoma(item.codeArr[2]).wdx]">{{ panduanhaoma(item.codeArr[2]).wdx }} </td>
             <td :class="fontColor[panduanhaoma(item.codeArr[2]).hds]">{{ panduanhaoma(item.codeArr[2]).hds }} </td>
             <td class="ltgreen">{{ panduanhaoma(item.codeArr[2]).fulu }} </td>
             <!-- -------- -->
             <td class="td-ball"> <p :class="Number(item.codeArr[3])>40?'senseball':'greyball'">{{item.codeArr[3]}}</p></td>
             <td :class="fontColor[panduanhaoma(item.codeArr[3]).ds]">{{ panduanhaoma(item.codeArr[3]).ds }} </td>
             <td :class="fontColor[panduanhaoma(item.codeArr[3]).dx]">{{ panduanhaoma(item.codeArr[3]).dx }} </td>
             <td :class="fontColor[panduanhaoma(item.codeArr[3]).wdx]">{{ panduanhaoma(item.codeArr[3]).wdx }} </td>
             <td :class="fontColor[panduanhaoma(item.codeArr[3]).hds]">{{ panduanhaoma(item.codeArr[3]).hds }} </td>
             <td class="ltgreen">{{ panduanhaoma(item.codeArr[3]).fulu }} </td>
             <!-- -------- -->
             <td class="td-ball"> <p :class="Number(item.codeArr[4])>40?'senseball':'greyball'">{{item.codeArr[4]}}</p></td>
             <td :class="fontColor[panduanhaoma(item.codeArr[4]).ds]">{{ panduanhaoma(item.codeArr[4]).ds }} </td>
             <td :class="fontColor[panduanhaoma(item.codeArr[4]).dx]">{{ panduanhaoma(item.codeArr[4]).dx }} </td>
             <td :class="fontColor[panduanhaoma(item.codeArr[4]).wdx]">{{ panduanhaoma(item.codeArr[4]).wdx }} </td>
             <td :class="fontColor[panduanhaoma(item.codeArr[4]).hds]">{{ panduanhaoma(item.codeArr[4]).hds }} </td>
             <td class="ltgreen">{{ panduanhaoma(item.codeArr[4]).fulu }} </td>
             <!-- -------- -->
             <td>{{getqianwuqiusum(item.codeArr)}}</td>
             <td :class="fontColor[panduanhaoma(getqianwuqiusum(item.codeArr)).ds]">{{panduanhaoma(getqianwuqiusum(item.codeArr)).ds}}</td>
             <td :class="fontColor[getqianwuqiusum(item.codeArr)>202?'大':'小']">{{getqianwuqiusum(item.codeArr)>202?'大':'小'}}</td>
             <td :class="fontColor[panduanhaoma(getqianwuqiusum(item.codeArr)).wdx]">{{panduanhaoma(getqianwuqiusum(item.codeArr)).wdx}}</td>

         
            <td :class="fontColor[Number(item.codeArr[0])>Number(item.codeArr[4])?'龙':'虎']"> {{Number(item.codeArr[0])>Number(item.codeArr[4])?'龙':'虎'}} </td>
       
          </tr>
        </table>
        <div class="page">
          <!-- :page-sizes="[20,50,100,200]" -->
          <el-pagination @current-change="handleCurrentChange" :current-page="pages.pagenum" :page-size="pages.pagecount" layout="total, prev, pager, next, jumper" :total="totals">
          </el-pagination>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import kjmixins from "../mixins/kjmixins";
export default {
  name: "kjkl8",
  props: [""],
  mixins: [kjmixins],
  data() {
    return {};
  },

  components: {},
  created() {},
  computed: {},

  methods: {
    // 判断号码各种值
    panduanhaoma(num){
      let obj={
        ds:'',
        dx:'',
        wdx:'',
        hds:'',
        fulu:''
      }
     obj.ds=Number(num)%2==0?'双':'单';
     obj.dx=Number(num)>40?'大':'小';
  //  尾大小
  let tmws =
      Number(num) > 9
        ? Number(num) + ""
        : "0" + Number(num);
 
    if (
      tmws[tmws.length-1] == 5 ||
      tmws[tmws.length-1] == 6 ||
      tmws[tmws.length-1] == 7 ||
      tmws[tmws.length-1] == 8 ||
      tmws[tmws.length-1] == 9
    ) {
      obj.wdx = "大";
    } else {
      obj.wdx = "小";
    }
      // 合单双
      let hssum = 0;
  if (Number(num) < 10) {
    hssum = Number(num);
  } else {
   let heshu = num + "";
    hssum = Number(heshu[0]) + Number(heshu[1]);
  }
  obj.hds=hssum % 2==0?'双':'单'
  obj.fulu=this.fulushouxi(num)
  return obj
    // -------------------------
    }
  },
};
</script>
<style  scoped>
.duil{
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.r-o-ssg .com-table tr td.ds_d,
.r-o-ssg .com-table tr td.dx_d {
  color: red;
}
.r-o-ssg .com-table tr td.ds_s,
.r-o-ssg .com-table tr td.dx_x {
  color: #00f;
}
li,
ul {
  list-style: none;
}
</style>